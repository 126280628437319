import { useState } from "react"

const useMailchimp = () => {
  const [isLoading, setIsloading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const add = async (email, tags = []) => {
    setIsloading(true)

    try {
      await fetch("/.netlify/functions/mailchimp3", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          email_address: email,
          tags: tags
        })
      })

      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    } finally {
      setIsloading(false)
    }
  }

  const send = async ({ email, name, phone, message }) => {
    setIsloading(true)

    try {
      await fetch("/.netlify/functions/sendEmail", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, name, phone, message })
      })

      setIsSuccess(true)
    } catch (e) {
      console.log(e)
    } finally {
      setIsloading(false)
    }
  }

  return { add, send, isLoading, isSuccess }
}

export default useMailchimp
