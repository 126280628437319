/* eslint-disable */

import React, { useState, useRef } from "react"
import Notification from "../components/notification"

export default function Example({
  title = "Sign up for our newsletter",
  description = "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat.",
  ctaText = "Join now",
  handleSubmit = email => {},
  isLoading = false,
  isSuccess = false
}) {
  const [email, setEmail] = useState("")
  const [show, setShow] = useState(false)
  const hidden = useRef(false)

  const handleInput = ({ target }) => {
    setEmail(target.value)
  }

  const onSubmit = e => {
    e.preventDefault()
    handleSubmit(email)
  }

  if (isSuccess && hidden.current === false) {
    setShow(true)
    hidden.current = true

    setTimeout(() => setShow(false), 7000)
  }

  const disabledCTA = isLoading || isSuccess

  return (
    <div className="max-w-7xl mx-auto lg:flex lg:items-center px-8 py-12">
      <div className="lg:w-0 lg:flex-1">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {title}
        </h2>
        <p className="mt-3 max-w-3xl text-lg text-jet">{description}</p>
      </div>
      <div className="mt-8 lg:mt-0 lg:ml-8">
        <form onSubmit={onSubmit} className="sm:flex">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            onChange={handleInput}
            id="email-address"
            name="email-address"
            type="email"
            value={email}
            autoComplete="email"
            required
            disabled={disabledCTA}
            className="w-full px-5 py-3 border border-gray-300 shadow-sm placeholder-gray-600 focus:ring-1 focus:ring-porsche-500 focus:border-porsche-500 sm:max-w-xs rounded-md"
            placeholder="Enter your email"
          />
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <button
              disabled={disabledCTA}
              type="submit"
              className="w-full flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white bg-porsche-700 hover:bg-porsche-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-porsche-500"
            >
              {ctaText}
            </button>
          </div>
          {isSuccess && <Notification show={show} />}
        </form>
      </div>
    </div>
  )
}
