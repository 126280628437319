import PropTypes from "prop-types"
import React from "react"

import Header from "./header"
import Footer from "./footer"
import Newsletter from "./newsletter"

import useMailchimp from "../hooks/useMailchimp"

function Layout({ children }) {
  const { add, isLoading, isSuccess } = useMailchimp()

  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      <Header />
      <main>{children}</main>
      <div className="bg-layered-bottom bg-cover bg-no-repeat bg-center pt-80 pb-48 lg:pt-96 lg:pb-96" />
      <div className="bg-sea-pink">
        <Newsletter
          title="A Taste Of Flow Freedom: FREEBIE!"
          description="Get a glimpse into my program by joining the FREE Flow Freedom Challenge sent straight to your inbox"
          handleSubmit={email => {
            add(email, ["10DayChallenge"])
          }}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
