/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { MenuIcon, XIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"

import Banner from "./banner"

const navigation = [
  { name: "Home", to: "/" },
  { name: "About Eden", to: "/about" },
  { name: "Let's chat", to: "/contact" },
  { name: " 1:1 Program + Bookings", to: "/programs" }
]

export default function Example() {
  return (
    <div className="relative bg-white z-50 sticky top-0  border-b-2 border-grey-100 shadow-sm ">
      <Banner />
      <Popover>
        <nav
          className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6 py-4 sm:py-6"
          aria-label="Global"
        >
          <div className="flex items-center flex-1">
            <div className="flex items-center justify-between w-full lg:w-auto relative">
              <Link to="/">
                <span className="sr-only">Sincerely</span>
                <img
                  className="w-32 h-8 mt-2"
                  width="100px"
                  height="20px"
                  src="/assets/sincerely-eden-logo-cropped.svg"
                  alt=""
                />
              </Link>
              <div className="-mr-2 flex items-center lg:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-jet hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-porsche-500">
                  <span className="sr-only">Open main menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <div className="hidden lg:block lg:ml-10 lg:space-x-10">
              {navigation.map(item => (
                <Link
                  key={item.name}
                  to={item.to}
                  className="font-medium text-jet hover:text-gray-900"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="hidden lg:absolute lg:flex lg:right-8 lg:items-center lg:justify-end lg:inset-y-0">
              <span className="inline-flex rounded-md shadow">
                <Link
                  to="/10-day-free-flow-freedom"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base rounded-md text-white  bg-porsche-700 hover:bg-porsche-900"
                >
                  10 Day Free Flow Freedom Challenge
                </Link>
              </span>
            </div>
          </div>
        </nav>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-32"
                    width="100px"
                    height="20px"
                    src="/assets/sincerely-eden-logo-cropped.svg"
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-jet hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-porsche-500">
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map(item => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className="block px-3 py-2 rounded-md text-base text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
              <Link
                to="/10-day-free-flow-freedom"
                className="block w-full px-5 py-3 text-center text-white bg-porsche-700 hover:bg-porsche-900"
              >
                10 Day Free Flow Freedom Challenge
              </Link>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}
