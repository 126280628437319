import React, { useEffect, useState } from "react"
import { XIcon } from "@heroicons/react/outline"
import { Link } from "gatsby"

export default function Example() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const shouldHideBanner = localStorage.getItem("closed-challenge-banner")

    // if an item does't exist, make the banner visible.
    if (!shouldHideBanner) {
      setIsVisible(true)
    }
  }, [])

  const handleClose = () => {
    setIsVisible(false)
    localStorage.setItem("closed-challenge-banner", "true")
  }

  return (
    <div className={`relative bg-porsche-700    ${!isVisible ? "hidden" : ""}`}>
      <div className="mx-auto max-w-7xl flex items-center justify-between">
        <Link
          className="flex-1"
          to="/10-day-free-flow-freedom"
          onClick={handleClose}
        >
          <div className="mx-auto py-3 px-4 sm:px-6">
            <div className="pr-16">
              <p className="font-medium text-white">
                <span className="inline md:hidden mr-2">
                  There&apos;s a free challenge.
                </span>
                <span className="hidden  md:inline mr-2">
                  Big news! There&apos;s a free challenge.
                </span>
                <span className=" inline-block">
                  <a href="#" className="text-white font-bold underline">
                    {" "}
                    Learn more <span aria-hidden="true">&rarr;</span>
                  </a>
                </span>
              </p>
            </div>
          </div>
        </Link>
        <div className="pt-1 pr-2 flex items-start sm:pt-1 sm:pr-4 sm:items-start">
          <button
            onClick={handleClose}
            type="button"
            className="flex p-2 rounded-md hover:bg-porsche-500 focus:outline-none focus:ring-2 focus:ring-white"
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  )
}
